import { createRouter, createWebHistory } from "vue-router";
import Home from "@/views/Home.vue";
import AboutUs from "@/views/AboutUs.vue";
import Support from "@/views/Support.vue";
import Terms from "@/views/Terms.vue";
import Privacy from "@/views/Privacy.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: Home
  },
  {
    path: "/about-us",
    name: "about-us",
    component: AboutUs
  },
  {
    path: "/support",
    name: "Support",
    component: Support
  },
  {
    path: "/terms",
    name: "terms",
    component: Terms
  },
  {
    path: "/privacy",
    name: "privacy",
    component: Privacy
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/"
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  setTimeout(() => window.scrollTo(0, 0));
  next();
});

export default router;
