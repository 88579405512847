<template>
  <div class="supporters">
    <div class="responsive-container">
      <h1>Supported by</h1>
      <div class="images">
        <a
          class="image startupschool"
          href="https://www.startupschool.org/companies/piebrowser"
          ></a>
        <a
          class="image atv"
          href="https://atlantatechvillage.com/"
          ></a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'supporters',
};
</script>

<style lang="scss" scoped>
@import '@/styles/colors.scss';
.supporters {
  text-align: center;
  margin-bottom: 90px;
  h1 {
    font-size: 2em;
    font-weight: 600;
    margin-bottom: 40px;
  }
  .images {
    display: flex;
    margin-bottom: 20px;
    flex-wrap: wrap;
    justify-content: center;
    .image {
      margin-right: 50px;
      cursor: pointer;
      &:active {
        transform: translateY(1px);
        opacity: 0.8;
      }
      &:last-child {
        margin-right: 0;
      }
      &.startupschool {
        max-width: 220px;
        width: 100%;
        height: 50px;
        background-image: url('../assets/ycss.png');
        background-size: contain;
        background-repeat: no-repeat;
      }
      &.atv {
        max-width: 211px;
        width: 100%;
        height: 50px;
        background-image: url('../assets/atv.svg');
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  .supporters {
    margin-bottom: 80px;
    h1 {
      font-size: 1.6em;
    }
    .images {
      flex-direction: column;
      align-items: center;
      .image {
        margin-right: 0;
        margin-bottom: 40px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
</style>
