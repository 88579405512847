<template>
  <div class="footer" v-if="show">
    <div class="responsive-container">
      <div>
        <router-link to="/about-us">About Us</router-link> •
        <!-- <span v-if="showFaqs"><router-link to="/faqs">FAQs</router-link> •</span> -->
        <span v-if="showContactUs"><a href="mailto:contact@piebrowser.com">Contact</a> •</span>
        <router-link to="/terms">Terms of Use</router-link> •
        <router-link to="/privacy">Privacy Policy</router-link>
      </div>
      <!-- <div class="disclaimer">
        Projected earnings are an estimation based on averages
        and vary depending on region. Your actual earnings may differ.
      </div> -->
      <div class="copyright">
        &copy; {{ new Date().getFullYear() }} Pie Technologies Inc. All rights reserved.
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  props: {
    showFaqs: {
      type: Boolean,
      default: true,
    },
    showContactUs: {
      type: Boolean,
      default: true,
    },
    allowSimple: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    show() {
      return this.allowSimple ? !('simple' in this.$route.query) : true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/colors.scss';
.footer {
  padding-bottom: 80px;
  text-align: center;
  div {
    margin-bottom: 20px;
    line-height: 1.6em;
  }
  a {
    margin: 0 14px;
    text-decoration: none;
    color: inherit;
    &:hover {
      color: $primary-color;
    }
  }
  .disclaimer, .copyright {
    opacity: 0.5;
  }
}
</style>
