<template>
  <div class="support" :class="{ simple: 'simple' in this.$route.query }">
    <Header :allowSimple="true" />
    <HeaderTitle title="Support" :centered="true" :allowSimple="true" />
    <div class="responsive-container">
      <div class="content">
        <h3>
          Have any questions, concerns, or feedback?
        </h3>
        <h3>
          Please reach out to us at
          <a href="mailto:contact@piebrowser.com">contact@piebrowser.com</a>.
        </h3>
      </div>
    </div>
    <Footer :showFaqs="false" :allowSimple="true" />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import HeaderTitle from "@/components/HeaderTitle.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "Support",
  components: {
    Header,
    HeaderTitle,
    Footer
  }
};
</script>

<style lang="scss">
@import "@/styles/colors.scss";
.support {
  .content {
    margin: 0 auto;
    margin-bottom: 60px;
    max-width: 600px;
    text-align: center;
  }
  &.simple {
    .content {
      .office {
        margin-top: 20px;
      }
      a {
        text-decoration: none;
        color: inherit;
        pointer-events: none;
      }
    }
  }
}
</style>
