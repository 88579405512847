<template>
  <div class="home">
    <Header />
    <!-- <FixedHeader @onButtonClick="openPopup()" /> -->
    <Hero @onButtonClick="openPopup()" />
    <!-- <About @onButtonClick="openPopup()" /> -->
    <!-- <Features /> -->
    <Supporters />
    <!-- <FaqsSection :limit="5" /> -->
    <!-- <div class="lower-get-started">
      <div class="responsive-container">
        <a class="btn" @click="openPopup()">Get started</a>
      </div>
    </div> -->
    <Footer />
    <!-- <GetStartedPopup :show="showPopup" @onClose="closePopup()" /> -->
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
// import FixedHeader from '@/components/FixedHeader.vue';
import Hero from "@/components/Hero.vue";
// import About from '@/components/About.vue';
// import GetStartedPopup from '@/components/GetStartedPopup.vue';
// import Features from '@/components/Features.vue';
import Supporters from "@/components/Supporters.vue";
// import FaqsSection from '@/components/FaqsSection.vue';
import Footer from "@/components/Footer.vue";

export default {
  name: "Home",
  components: {
    Header,
    // FixedHeader,
    Hero,
    // About,
    // GetStartedPopup,
    // Features,
    Supporters,
    // FaqsSection,
    Footer
  },
  data() {
    return {
      showPopup: false
    };
  },
  methods: {
    openPopup() {
      this.showPopup = true;
    },
    closePopup() {
      this.showPopup = false;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/colors.scss";
.home {
  .lower-get-started {
    text-align: center;
    margin-bottom: 84px;
  }
}
</style>
